import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInStatus = (localStorage.getItem('loggedIn') === "true") || false;
  public user = localStorage.getItem('user') || false;

  constructor(private http: HttpClient,
    private router: Router) {
   }

  get isLoggedIn(){
    //Check with the API that they are logged in.
    this.http.get(environment.api.Url + 'contact?access_token=' + localStorage.getItem('access_token'))
    .subscribe(
      data=> {
        // go to admin
        const user = data['data']['user'];
        this.user = user;
          if(this.user['is_staff'] !== true){
            this.doLogout();
          } else {
            localStorage.setItem('user', JSON.stringify(this.user));
          }
    },
  error=>{
    this.doLogout();
  }

    );

    return this.loggedInStatus;
  }

  doLogout(){
    this.setLoggedIn(false);
    localStorage.setItem('access_token', 'false');
    localStorage.setItem('requested_page', this.router.url);
    localStorage.setItem('user', 'false');
    this.router.navigate(['auth/login']);
  }

  setLoggedIn(value:boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', String(value));
  }
  
  getUserDetails(email,password){
    const body = new HttpParams()
      .set("client_id", environment.api.id)
      .set("client_secret", environment.api.secret)
      .set("email", email)
      .set("password", password)
      .set("grant_type", "password");

    return this.http.post(
      environment.api.Url + 'oauth/access_token' ,
      body.toString(),{
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );

  }



}