export const locale = {
    lang: 'tr',
    data: {
        'NAV': {
            'MODULES': 'Ana menü',
            'DASHBOARD'  : 'gösterge paneli',
            'SUBMMISSIONS'    : 'gönderimler'
        }
    }
};
