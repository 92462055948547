// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var xhttp = new XMLHttpRequest();
export var environment = {
    production: true,
    hmr       : false,
    systemName: "Loading please wait...",
    api: {
        Url: "",
        secret: "",
        id: ""
    },
    logo:'assets/images/logos/capture.png', // Should be moved to an API call
    theme:'' // Should be moved to an API call
};

    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
           environment = JSON.parse(this.responseText);
            
      //  } 
            //Matt Dev
      //      environment = JSON.parse('{"production":true,"hmr":false,"systemName":"VB Review Studio","api":{"Url":"https://visitbritain.captureweb.co.uk/api/","secret":"test","id":"test"}}');
         //   environment.logo = 'assets/images/logos/vb.png';
         //   environment.theme = 'indigo-theme';

            document.title = environment.systemName;

            try{
                if(environment.logo==''){environment.logo = 'assets/images/logos/capture.png';}
                if(environment.theme !=''){document.getElementsByTagName('body')[0].classList.add(environment.theme);}
            }catch(e){
                //Don't Care!
            }

            /*
             ____  __  _  _   __   __ _    _  _   __   ____  ____    _  _  ____    ____   __     __  ____  _   
            / ___)(  )( \/ ) /  \ (  ( \  ( \/ ) / _\ (    \(  __)  ( \/ )(  __)  (    \ /  \   (  )(_  _)/ \  
            \___ \ )( / \/ \(  O )/    /  / \/ \/    \ ) D ( ) _)   / \/ \ ) _)    ) D ((  O )   )(   )(  \_/  
            (____/(__)\_)(_/ \__/ \_)__)  \_)(_/\_/\_/(____/(____)  \_)(_/(____)  (____/ \__/   (__) (__) (_)

             ____  ____  _  _   __   _  _  ____    ____  _  _  __  ____     __   ____   __   ____ 
            (  _ \(  __)( \/ ) /  \ / )( \(  __)  (_  _)/ )( \(  )/ ___)   / _\ / ___) / _\ (  _ \
             )   / ) _) / \/ \(  O )\ \/ / ) _)     )(  ) __ ( )( \___ \  /    \\___ \/    \ ) __/
            (__\_)(____)\_)(_/ \__/  \__/ (____)   (__) \_)(_/(__)(____/  \_/\_/(____/\_/\_/(__)  
            */            

            var link = <HTMLLinkElement>document.querySelector("link[rel*='icon']") || <HTMLLinkElement>document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = environment.logo;
            document.getElementsByTagName('head')[0].appendChild(link);
            var img = <HTMLImageElement>document.getElementById('loadingLogo');
            img.src = environment.logo;
            
            /*
             ____  __ _  ____    ____  ____  _  _   __   _  _  ____    _  _  ____  _   
            (  __)(  ( \(    \  (  _ \(  __)( \/ ) /  \ / )( \(  __)  ( \/ )(  __)/ \  
             ) _) /    / ) D (   )   / ) _) / \/ \(  O )\ \/ / ) _)   / \/ \ ) _) \_/  
            (____)\_)__)(____/  (__\_)(____)\_)(_/ \__/  \__/ (____)  \_)(_/(____)(_) 
            */
            }

    };

    
  xhttp.open("GET", '/configs/' + window.location.hostname +'.json');//window.location.hostname
  xhttp.send();