import { NgModule, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from '@capture/auth.guard';
import { AuthService } from '@capture/auth.service';

import { MatButtonModule, MatButtonToggleModule, MatFormFieldModule, MatIconModule, MatListModule, MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTabsModule } from '@angular/material';

import { DragToSelectModule } from 'ngx-drag-to-select';
import {HotkeyModule} from 'angular2-hotkeys';

import { ApmService } from '@elastic/apm-rum-angular'

const appRoutes: Routes = [
    {
        path        : 'auth', //Auth Module does not require authentication
        loadChildren: './cui/auth/auth.module#AuthModule'
    },
    {
        path        : 'errors', //Auth Module does not require authentication
        loadChildren: './cui/errors/errors.module#ErrorsModule'
    },    
    {
        path        : 'submissions', //Submissions Module
        loadChildren: './cui/submissions/submissions.module#SubmissionsModule',
        canActivate: [AuthGuard]
    },
    {
        path      : '**', //If no match, go to the Submissions Dashboard
        redirectTo: 'submissions/dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        HotkeyModule.forRoot(),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        MatButtonModule, MatButtonToggleModule, MatFormFieldModule, MatIconModule, MatListModule, MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTabsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        DragToSelectModule.forRoot()
    ],
    providers: [AuthService,AuthGuard,{provide: ApmService,useClass: ApmService, deps: [Router]}],
    bootstrap   : [
        AppComponent
    ],
    
})
export class AppModule
{

    constructor(@Inject(ApmService) service: ApmService) {
        // API is exposed through this apm instance
        const apm = service.init({
          serviceName: 'Backoffice-UI',
          serverUrl: 'https://61102a05978440c3bcfd29d6e9074791.apm.eu-west-2.aws.cloud.es.io:443',
          environment: 'LIVE'
        });
    
    }

}
