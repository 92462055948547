export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'MODULES': 'Menú principal',
            'DASHBOARD'  : 'Tablero',
            'SUBMMISSIONS'    : 'Presentaciones'
        }
    }
};
