export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'MODULES': 'Main Menu',
            'DASHBOARD'  : 'Dashboard',
            'SUBMISSIONS'    : {
                'TITLE': 'Submissions',
                'DASHBOARD' : 'Dashboard',
                'BATCHES' : 'Batches',
                'MYBATCHES' : 'My Batches'
            }
        }
    }
};
