export class SubmissionsFakeDB
{
    public static dashboard = {
        'stats':
            [
                {
                'ranges'      : {
                    0 : 'Current',
                    1 : 'Last 7 Days',
                    2 : 'Month to Date'
                },
                'currentRange': '1',
                'data'        : {
                    'label': 'New',
                    'count': {
                        0 : 5,
                        1 : 7,
                        2: 21
                    },
                    'extra': [
                        {
                            'label': 'Assigned',
                            'count': {
                                0 : 3,
                                1 : 7,
                                2: 8
                            }
                        },
                        { 
                            'label': 'Un-Assigned',
                            'count': {
                                0 : 3,
                                1 : 7,
                                2: 8
                            }
                        }
                    ]    
                },
                'detail'      : 'Shows a summary of NEW batches (i.e. those which have not been edited). Click on any of the numbers to jump to the batches list'
                },
                {
                    'ranges'      : {
                        0 : 'Current',
                        1 : 'Today',
                        2 : 'Last 7 Days'
                    },
                    'currentRange': '1',
                    'data'        : {
                        'label': 'In Progess',
                        'count': {
                            0 : 5,
                            1 : 7,
                            2: 21
                        },
                        'extra': [
                            {
                                'label': 'Assigned',
                                'count': {
                                    0 : 3,
                                    1 : 7,
                                    2: 8
                                }
                            },
                            { 
                                'label': 'Un-Assigned',
                                'count': {
                                    0 : 3,
                                    1 : 7,
                                    2: 8
                                }
                            }
                        ]    
                    },
                    'detail'      : 'Summary data of all batches with a status group for New'
                    },
                    {
                        'ranges'      : {
                            0 : 'Current',
                            1 : 'Today',
                            2 : 'Last 7 Days'
                        },
                        'currentRange': '1',
                        'data'        : {
                            'label': 'Accepted',
                            'count': {
                                0 : 5,
                                1 : 7,
                                2: 21
                            },
                            'extra': [
                                {
                                    'label': 'Assigned',
                                    'count': {
                                        0 : 3,
                                        1 : 7,
                                        2: 8
                                    }
                                },
                                { 
                                    'label': 'Un-Assigned',
                                    'count': {
                                        0 : 3,
                                        1 : 7,
                                        2: 8
                                    }
                                }
                            ]    
                        },
                        'detail'      : 'Summary data of all batches with a status group for New'
                        },
                        {
                            'ranges'      : {
                                0 : 'Current',
                                1 : 'Today',
                                2 : 'Last 7 Days'
                            },
                            'currentRange': '1',
                            'data'        : {
                                'label': 'Rejected',
                                'count': {
                                    0 : 5,
                                    1 : 7,
                                    2: 21
                                },
                                'extra': [
                                    {
                                        'label': 'Assigned',
                                        'count': {
                                            0 : 3,
                                            1 : 7,
                                            2: 8
                                        }
                                    },
                                    { 
                                        'label': 'Un-Assigned',
                                        'count': {
                                            0 : 3,
                                            1 : 7,
                                            2: 8
                                        }
                                    }
                                ]    
                            },
                            'detail'      : 'Summary data of all batches with a status group for New'
                            }                                                            
            ],
        'widget5': {
            'title'    : 'Asset Overview',
            'ranges'   : {
                '2W': 'Last 7 Days',
                'MTD' : 'MTD'
            },
            'currentRange' : '2W',
            'mainChart': {
                '2W': [
                    {
                        'name'  : 'Accepted',
                        'series': [
                            {
                                'name' : 'Mon',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Tue',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Wed',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Thu',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Fri',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Sat',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Sun',
                                'value': Math.floor(Math.random() * 60) + 1  
                            }
                        ]
                    },
                    {
                        'name'  : 'Rejected',
                        'series': [
                            {
                                'name' : 'Mon',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Tue',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Wed',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Thu',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Fri',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Sat',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : 'Sun',
                                'value': Math.floor(Math.random() * 60) + 1  
                            }
                        ]
                    }                
                ],
                'MTD': [
                    {
                        'name'  : 'Accepted',
                        'series': [
                            {
                                'name' : '1',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '2',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '3',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '4',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '5',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '6',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '7',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '8',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '9',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '10',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '11',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '12',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '13',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '14',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '15',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '16',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '17',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '18',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '19',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '20',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '21',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '22',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '23',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '24',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '25',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '26',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '27',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '28',
                                'value': Math.floor(Math.random() * 60) + 1  
                            }                                                                                    
                        ]
                    },
                    {
                        'name'  : 'Rejected',
                        'series': [
                            {
                                'name' : '1',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '2',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '3',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '4',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '5',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '6',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '7',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '8',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '9',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '10',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '11',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '12',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '13',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '14',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '15',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '16',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '17',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '18',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '19',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '20',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '21',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '22',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '23',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '24',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '25',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '26',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '27',
                                'value': Math.floor(Math.random() * 60) + 1  
                            },
                            {
                                'name' : '28',
                                'value': Math.floor(Math.random() * 60) + 1  
                            }     
                        ]
                    }                
                ]
            }
        }
    };
}
