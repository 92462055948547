import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'modules',
        title    : 'Modules',
        translate: 'NAV.MODULES',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'submissions',
                title    : 'Submissions',
                translate: 'NAV.SUBMISSIONS.TITLE',
                type     : 'collapsable',
                icon     : 'today',
                children : [
               /*     {
                        id   : 'submissionsDashboard',
                        title: 'Dashboard',
                        translate: 'NAV.SUBMISSIONS.DASHBOARD',
                        type : 'item',
                        url  : '/submissions/dashboard'
                    },                    */
                    {
                        id   : 'submissionsallbatches',
                        title: 'Batches',
                        translate: 'NAV.SUBMISSIONS.BATCHES',
                        type : 'item',
                        url  : '/submissions/batches'
                    }
                    //,
                    //{
                    //    id   : 'submissionsmybatches',
                    //    title: 'My Batches',
                    //    translate: 'NAV.SUBMISSIONS.MYBATCHES',
                     //   type : 'item',
                    //    url  : '/submissions/mybatches'
                    //}
                ]
            }
            
        ]
    }

];
